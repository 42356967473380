import { default as indexkcS0xdy5DOMeta } from "/home/runner/work/augwa/augwa/web/src/pages/index.vue?macro=true";
import { default as indexHIAuyVNq5XMeta } from "/home/runner/work/augwa/augwa/web/src/pages/privacy/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/augwa/augwa/web/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/runner/work/augwa/augwa/web/src/pages/index.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/home/runner/work/augwa/augwa/web/src/pages/privacy/index.vue")
  }
]