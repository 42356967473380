<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const $config = useRuntimeConfig()
const { locale } = useI18n()
const i18nHead = useLocaleHead({
  addSeoAttributes: true
})

const headMeta = computed(() => {
  const metadata = {
    'og:type': {
      key: 'og:type',
      property: 'og:type',
      content: 'website'
    },
  } as Record<string, any>

  for (const item of i18nHead.value.meta || []) {
    const key = item.key || uuid()
    metadata[key as string] = item
  }

  return Object.values(metadata)
})

useHead({
  htmlAttrs: {
    lang: locale.value
  },
  meta: [...(headMeta.value || [])],
  title: 'Helping residential cleaners grow and scale their businesses | Augwa'
})
</script>
